import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "validation": {
        "min": "Please enter at least 3 characters",
        "minMessage": "Please enter at least 15 characters",
        "required": "This field is required",
        "phone": "Enter a valid phone number",
        "email": "Please enter a valid email address",
        "consent": "Accept the conditions for storage and processing of personal data"
      },
      "header": {
        "home": "Home",
        "news": "News",
        "products": "Products",
        "implementation": "Implementation",
        "about": "About us",
        "trainings": "Courses",
        "customers": "Our clients",
        "button": "Contact",
      },
      "hero": {
        "title": "The best BIM solutions on the market",
        "copyright": "2023<br/>ALL RIGHT RESERVED",
        "subtitle": "We are representatives of the Main Distributor of GRAPHISOFT in Poland - WSC Sp. z o. o.",
      },
      "software": {
        "title": "Software",
        "1": {
          "title": "ARCHICAD: The best BIM tool!",
          "subtitle": "Archicad allows you to focus on what designers, contractors and investors do best, i.e. design and build.",
          "subtitle2": "The world of BIM is vast! Archicad currently supports more than 40 export and import formats, including classics such as DWG, PDF and XLS, as well as the modern OPEN BIM formats IFC and BCF.",
          "listTitle": "Why Archicad:",
          "list": {
            "1": "The most advanced software developed for more than 30 years",
            "2": "Automation of the design process, preparation of documentation and management of building information",
            "3": "The possibility of preparing thorough analyses of the building's life cycle and sustainability reports",
            "4": "Attractive possibility to present a building - visualisations, animations, virtual tours - we synchronise the Archicad model with the leading visualisation software on the market",
            "5": "Possibility of cooperation with other participants in the investment process",
            "6": "Saving designers tens of hours of work on updating and changing documentation less design errors - build a virtual building in Archicad to simulate our solutions",
            "7": "Ease of implementation of the software in various operating systems (macOS and Windows) a rich database of libraries and parametric objects that can be used in the project",
            "8": "Together with the WSC, we provide our clients with a package of more than 50 unique GDL objects, which facilitate the work on the model and the preparation of the documentation."
          },
          "end": "We pass on our experience by implementing the construction team. We adapt the tools and stages of training and support to suit the time of our clients.",
          "footer": {
            "title": "Join the satisfied users of Archicad Graphisoft!",
            "content": "Archicad gives you the opportunity to focus on design in aBIM environment. Archicad enables design teams to be perfectly organised and to keep their work flowing (also remotely).",
            "ask": "Would you like more information about Archicad? Write or call us!",
            "button": "Do you have more questions? Contact us"
          },
        },
        "2": {
          "title": "BIMcloud: Real-time collaboration",
          "content": "Secure teamwork in real time. We can work as a team of several designers on a single design, even when out of the office. Full project access and feature management for each user",
          "content2": "With BIMcloud we have a coherent design and secure data (creation of a backup copy of the design). BIMCloud is a very stable and efficient system for teamwork even on very large designs. To work remotely on a design you need to set up access (VPN recommended), a computer with a licence and internet access -> everything will work without problems!",
          "listTitle": "BIMCloud is available in versions:",
          "list": {
            "1.1": "BIMcloudBasic:",
            "1.2": "Licence-free, free version of the system for local use. Fully functional even for a team of more than ten.",
            "2.1": "BIMcloud:",
            "2.2": "Paid version for advanced teamwork for a company with, for example, 3 or 4 offices in different locations.",
            "3.1": "BIMcloudSaaS:",
            "3.2": "Paid-as-a-service version available at any time, no need to invest in additional IT solutions. We have the entire server available on Graphisoft's virtual servers."
          },
          "footer": {
            "ask": "Would you like more information? Write or call us!",
            "button": "Contact us"
          }

        },
        "3": {
          "title": "BIMx: Presentation and coordination",
          "content": "BIMx is the perfect solution for connecting design offices with investors and construction sites. Take the presentation of your project to the next level. Sheets of documentation integrated into a model in which each element has a set of necessary information -> this is how BIMx applications can be described.",
          "content2": "The award-winning BIMxapp offers a unique technology for viewing, presenting and coordinating design work on iOS or Android mobile devices or via a web browser.",
          "footer": {
            "ask": "Would you like more information? Write or call us!",
            "button": "Contact us"
          }
        },

      },
      "news": {
        "title": "News",
        "slider": {
          "title": "Contact us!",
          "write": "WRITE",
          "call": "CALL"
        }
      },
      "products": {
        "title": "Products and services",
        "subtitle": "DESIGN AND MODEL IN BIM - Faster, easier and more fun!",
        "paragraph": "We advise in choosing the best solution for our clients. We provide an attractive and best offer on the market.",
        "designer": {
          "title": "BIM Software for Designers",
          "1": "Software for architects, constructors, installers, engineers, and more!",
          "2": "The solutions we offer are suitable for all types of building design, but also for architects and constructors. Archicad is a versatile tool that is suitable for all types of design.",
          "footer": "If you are interested in learning more about BIM tools",
          "ask": "The best offer for Our Customers!!!"
        },
        "architect": {
          "title": "Archicad for Architects:",
          "1": "Automation of documentation preparation, investment estimates and statements and presentation of visualisations",
          "2": "Implementation of an integrated model with any object information resource interdisciplinary coordination - communication in the investment process",
          "3": "Implementation of visualisations and virtual tours - attractive presentation of the project also on mobile devices",
          "4": "Making changes at the design stage - possibility",
          "5": "Time saving in the preparation of documentation at the concept, building permit design or detailed design stageo",
        },
        "constructor": {
          "title": "Archicad for Constructors:",
          "1": "Preparation of the structural model has never been so easy interdisciplinary coordination and cooperation with architects preparation of analytical models for structural calculations modelling of complex structural elements.",
          "2": "Communication and cooperation based on the new OPENBIM-IFC4 and BCF standards 2.1.",
        },
        "installer": {
          "title": "Archicad for Installers:",
          "1": "Modelling and coordination of the installation design in the Archicad environment of the installation component lists",
          "2": "Preparation of executive models",
        },
        "contractor": {
          "title": "BIM solutions for Contractors",
          "1": "BIM for Contractors - Archicad-based Solutions",
          "2": "All construction in a virtual model! During construction, construction control based on a BIM model will facilitate decision-making, improve coordination and facilitate communication with the construction manager or contract manager. The BIM model will make it possible to make the right decisions and reduce the number of problems to be solved at the investment stage. The on-site model provides a range of benefits and opportunities for use at the construction stage.",
          "3": "Whether you get the model from the designers or use the modelling tools - coordinating the project in a BIM/3D environment is a great convenience for any size of project from 1M to ....M.",
          "4": "We provide:",
          "5": "preparation of a suitable solution",
          "6": "coordination of the preparation of the necessary infrastructure (if required) training of the team",
          "7": "day-to-day servicing and support of the team infrastructure maintenance",
          "8": "We pass on our experience by implementing the construction team. We adapt the tools and stages of training and support to suit the time of our clients.."
        },
        "investor": {
          "title": "BIM Solutions for Investors",
          "1": "BIM for Investors - How can we use BIM tools?",
          "2": "Investing is a complex and multi-pronged process in which obtaining key data at the various stages of an investment is very important. Therefore, an important aspect of investment is the use of effective tools for investment planning and design. Every investor should rely on reliable investment data, which will allow them to adequately plan the project from investment financing to commercialisation. The economic and financial data that can be generated from the model reduces investment risk and facilitates decision-making. Archicad is also designed for this!",
          "3": "Spatial analysis and planning for land acquisition:",
          "4": "The BIM modelling tools allow for a very effective planning of the first steps and guidelines for the project. The analysis of the plot's absorption capacity, as well as the analysis of solar exposure or shading, is one of the most important problems designers and investors face, which often results in a number of costly and time-consuming changes at a later stage of the project.",
          "5": "Cost monitoring:",
          "6": "The BIM model for the Investor is also a database of information on investment costs. Preliminary values, analysis and the use of substitute solutions are commonplace. The ongoing analysis of the investment through the use of BIM modelling offers many advantages for the investor's investment management.",
          "7": "Sales tools:",
          "8": "The commercialisation of an investment is an important element of the entire process. Therefore, we can facilitate this aspect for investors. By using proven tools, we are able to present the project in an attractive manner.",
        },
        "ask": "Do you have more questions?",
        "meet": "Let's meet!",
        "signature": "DVM Group, Szervita Square"
      },
      "aboutUs": {
        "title": "About us",
        "subtitle": "Why should we work together?",
        "experince": {
          "title": "Experience",
          "content": "Our team is a group of active designers who use our solutions in their daily work. We know how to make your work easier in a BIM environment."
        },
        "technology": {
          "title": "Technology and Practice",
          "content": "Our team is an experienced team of professionals who also work on a daily basis in Our Group of Companies: BIM Monumesnts and Digital Building Data working with the best architectural firms, Investors and Contractors in Poland."
        },
        "it": {
          "title": "IT & BIM Solutions",
          "content": "We tailor solutions to the needs of Our Clients.  Together with our IT partners we can provide a complete solution."
        }
      },
      "trainings": {
        "title": "Courses",
        "content.1": "We will teach your team to use our solutions in the most effective way.",
        "content.2": "We prepare training dedicated to Design Studios, Investors or General Contractors",
        "subtitle": "WE KNOW EVERYTHING ABOUT ARCHICAD!",
        "sectionTitle": "Why should you train with us?",
        "link": "EXPLORE THE DETAILS",
        "extra": "Do you need dedicated training for your company?",
        "footer.button": "CONTACT US",
      },
      "clients": {
        "title": "Our clients",
        "subtitle": "Stories of our clients",
        "content": "We are passionate about what we do. The stories of our clients confirm that it is worth working with us."
      },
      "contact": {
        "title": "Contact",
        "subtitle": "Company details and contact",
        "office": "SALES OFFICE",
        "companyData": "COMPANY DATA",
        "infoFooter": "BIM solutions for your company",
        "form": {
          "name": "Name surname",
          "namePlaceholder": "Adam Smith",
          "phone": "Phone",
          "email": "Email",
          "emailPlaceholder": "adam.smith@gmail.com",
          "subject": "Subject",
          "message": "Message",
          "consent": "*I consent to the processing of my personal data in accordance with the Data Protection Act in connection with my enquiry via the contact form above.",
          "send": "Send"
        }
      },
      "footer": {
        "copyright": "© 2023 BIMOTEKA | All rights reserved"
      },
      "404": {
        "notFound": "Page not found",
        "content": "Sorry! We couldn’t find the page you were looking for.",
        "back": "Back to home"
      },
      "cookie": {
        "content": "This website uses cookies to make our service work better.",
        "link": "Learn more",
        "href": "https://allaboutcookies.org/",
        "button": "OK"
      }
    }
  },
  pl: {
    translation: {
      "validation": {
        "min": "Proszę o podanie przynajmniej 3 znaków",
        "minMessage": "Proszę o podanie przynajmniej 15 znaków",
        "required": "To pole jest wymagane",
        "phone": "Proszę o podanie prawidłowego numeru telefonu",
        "email": "Proszę o podanie prawidłowego adresu e-mail",
        "consent": "Zakceptuj warunki przechowywania oraz przetwarzania danych osobowych."
      },
      "header": {
        "home": "Home",
        "news": "Aktualności",
        "products": "Produkty",
        "implementation": "Wdrożenia",
        "about": "O nas",
        "trainings": "Szkolenia",
        "customers": "Nasi klienci",
        "button": "Kontakt",
      },
      "hero": {
        "title": "Najlepsze rozwiązania BIM na rynku",
        "copyright": "2023<br/>ALL RIGHT RESERVED",
        "subtitle": "Jesteśmy przedstawicielami Głównego Dystrybutora firmy GRAPHISOFT w Polsce - WSC Sp. z o.o.",
      },
      "software": {
        "title": "Oprogramowanie",
        "1": {
          "title": "ARCHICAD: Najlepsze narzędzie BIM!",
          "subtitle": "Archicad pozwala skupić się na tym, co projektanci, wykonawcy oraz inwestorzy robią najlepiej czyli na projektowaniu i budowaniu.",
          "subtitle2": "Świat BIM jest w zasięgu ręki! Obecnie Archicad obsługuje ponad 40 formatów eksportu i importu, w tym klasyczne, takie jak DWG, PDF i XLS, a także współczesne formaty OPEN BIM — IFC i BCF",
          "listTitle": "Dlaczego Archicad:",
          "list": {
            "1": "Najbardziej zaawansowane oprogramowanie rozwijane od ponad30lat",
            "2": "Automatyzacja procesu projektowego, przygotowania dokumentacji i zarządzania informacjami o budynku",
            "3": "Możliwość przygotowania dokładnych analiz cyklu życia budynku i raportów dotyczących zrównoważonego rozwoju",
            "4": "Atrakcyjna możliwość prezentacji budynku -wizualizacje,animacje,spacery wirtualne- model z Archicad synchronizujemy z wiodącymi programami do wizualizacji na rynku",
            "5": "Możliwość współpracy z innymi uczestnikami procesu inwestycyjnego",
            "6": "oszczędność dziesiątek godzin pracy projektantów nad aktualizacją i zmianami w dokumentacji mniejsza ilość błędów projektowych- budujemy w Archicad wirtualny budynek, tworząc symulację naszych rozwiązań",
            "7": "Łatwość wdrożenia oprogramowania na różnych systemach operacyjnych (macOS oraz Windows) bogata baza bibliotek i parametrycznych obiektów, które można wykorzystać w projekcie",
            "8": "Wraz z WSC naszym klientom dostarczamy pakiet ponad 50 unikalnych obiektów GDL, które ułatwiają pracę nad modelem oraz przygotowaniem dokumentacji"
          },
          "end": "Nasze doświadczenie przekazujemy wdrażając zespół budowy. Dostosowujemy narzędzia oraz etapy szkolenia i wsparcia dostosowując do porze naszych Klientów.",
          "footer": {
            "title": "Dołącz do zadowolonych użytkowników Archicad Graphisoft!",
            "content": "Archicad daje możliwość skoncentrowania się zaprojektowaniu w środowisku BIM. Archicad umożliwia zespołom projektowym doskonałą organizację pracy i zachowanie płynności pracy (również zdalnej)",
            "ask": "Chcesz więcej informacji o Archicad? Napisz lub Zadzwoń do Nas!",
            "button": "Masz więcej pytań? Skontaktuj się z nami"
          },
        },
        "2": {
          "title": "BIMcloud: Współpraca w czasie rzeczywistym",
          "content": "Bezpieczna praca zespołowa w czasie rzeczywistym. Możemy pracować w zespole kilku projektantów w jednym projekcie, również poza biurem. Pełen dostęp do projektu i zarządzanie funkcjami dla każdego użytkownika.",
          "content2": "Dzięki BIMcloud mamy spójny projekt oraz bezpieczne dane(tworzenie kopii zapasowej projektu).  BIMCloud to bardzo stabilny i wydajny system pracy zespołowej nawet na bardzo dużych projektach. Aby pracować zdalnie nad projektem potrzebujesz skonfigurowanie dostępu (rekomendowany VPN), komputer z licencją oraz dostęp do internetu -> wszystko będzie działać bez problemów!",
          "listTitle": "BIMCloud wstępuje w wersjach:",
          "list": {
            "1.1": "BIMcloudBasic:",
            "1.2": "Wersja nie wymagająca licencji, darmowa wersja systemu do użytku na lokalnym serwerze. W pełni sprawdzi się nawet w kilkunastoosobowym zespole",
            "2.1": "BIMcloud:",
            "2.2": "Wersja płatna, przeznaczona do zaawansowanej pracy zespołowej dla pracowni, która ma np. 3,4 siedziby w różnych lokalizacjach",
            "3.1": "BIMcloudSaaS:",
            "3.2": "Wersja płatna–jako usługa dostępna w każdym momencie, nie mapotrzeby inwestowania w dodatkowe rozwiązania informatyczne. Cały serwer mamy dostępny na wirtualnych serwerach Graphisoft."
          },
          "footer": {
            "ask": "Chcesz więcej informacji? Napisz lub Zadzwoń do Nas!",
            "button": "Kontakt"
          }
        },
        "3": {
          "title": "BIMx: Prezentacja i koordynacja",
          "content": "BIMx jest doskonałym rozwiązaniem łączącym biura projektowe z inwestorami i placami budów. Przejdź na wyższy poziom w prezentacji swojego projektu. Arkusze z dokumentacją zintegrowane z modelem, w którym każdy element ma zestaw potrzebnych informacji -> tak właśnie można opisać aplikacje BIMx.",
          "content2": "Wielokrotnie nagradzana aplikacja BIMx oferuje unikalną technologię przeglądania, prezentacji i koordynacji prac projektowych na urządzeniach mobilnych z systemem iOS lub Android lub poprzez przeglądarkę internetową.",
          "footer": {
            "ask": "Chcesz więcej informacji? Napisz lub Zadzwoń do Nas!",
            "button": "Kontakt"
          }
        },

      },
      "news": {
        "title": "Aktualnośći",
        "slider": {
          "title": "Skontaktuj się z Nami!",
          "write": "NAPISZ",
          "call": "ZADZWOŃ"
        }
      },
      "products": {
        "title": "Produkty i usługi",
        "subtitle": "PROJEKTUJ I MODELUJ W BIM - Lepiej, przyjemniej i szybciej!",
        "paragraph": "Podpowiadamy w doborze najlepszego rozwiązania dla naszych klientnów. Zapewniamy atrakcyjną i najlepszą ofertę na rynku.",
        "designer": {
          "title": "Oprogramowanie BIM dla Projektantów",
          "1": "Oprogramowanie dla architektów, konstruktorów, instalatorów, inżynierów, i nie tylko!",
          "2": "Rozwiązania jakie proponujemy sprawdzają się w wszelkiego rodzaju projektowaniu obiektów kubaturowych, ale i nie tylko.Wiodącą grupą naszych klientów są pracownie architektonicznie.Ale również wdrażaliśmy do firmy zajmujące się projektowaniem instalacji bądź też konstruktorów. Archicad jest uniwersalnym narzędziem, które sprawdza się w każdym typie projektowania.",
          "footer": "Jeśli jesteś zainteresowany poznaniem narzędzi BIM",
          "ask": "Najlepsza oferta dla Naszych Klientów!!!"
        },
        "architect": {
          "title": "Archicad dla Architektów:",
          "1": "Automatyzacja przygotowania dokumentacji, przedmiary inwestycji i zestawienia oraz prezentacja wizualizacji",
          "2": "Wykonanie integrowanego modelu z dowolnym zasobem informacji o obiekcie koordynacji międzybranżowa - komunikacja w procesie inwestycyjny",
          "3": "Wykonanie wizualizacji oraz spacerów wirtualnych-atrakcyjna prezentacja projektu również na urządzeniach mobilnych",
          "4": "Wprowadzanie zmian na etapie projektu- możliwość",
          "5": "Oszczędność czasu w przygotowaniu dokumentacji na etapie koncepcji, projektu budowlanego lub wykonawczego",
        },
        "constructor": {
          "title": "Archicad dla Konstruktorów:",
          "1": "Przygotowanie modelu konstrukcji nigdy nie było takie łatwe łatwa koordynacja międzybranżowa i współpraca z architektami przygotowanie modeli analitycznych do obliczeń konstrukcyjnych modelowanie złożonych elementów konstrukcyjnych.",
          "2": "Komunikacja i współpraca w oparciu o najnowsze standardy OPENBIM–IFC4iBCF 2.1.",
        },
        "installer": {
          "title": "Archicad dla Instalatorów:",
          "1": "Modelowanie i koordynacja projektu instalacji w środowisku Archicad zestawienia elementów instalacji",
          "2": "Przygotowanie modeli wykonawczych",
        },
        "contractor": {
          "title": "Rozwiązania BIM dla Wykonawców",
          "1": "BIM dla Wykonawców - Rozwiązania na bazie Archicada",
          "2": "Cała budowa w wirtualnym modelu! W czasie realizacji inwestycji kontrola budowy na bazie modelu BIM ułatwi podejmowanie decyzji, usprawni koordynację oraz ułatwi komunikację z kierownikiem budowy lub menadżerem kontraktu. Model BIM umożliwi podjęcie trafnych decyzji oraz pozwoli zmniejszyć ilość problemów do rozwiązania na etapie inwestycji. Model na budowie to szereg korzyści i możliwości wykorzystania na etapie realizacji.",
          "3": "Niezależnie od faktu, czy otrzymujemy model od projektantów, czy też wykorzystujesz narzędzia do modelowania - koordynacja projektu w środowisku BIM/3D to wielkie ułatwienie dla każdej wielkości inwestycji od 1M do ….M.",
          "4": "Zapewniamy:",
          "5": "Przygotowanie odpowiedniego rozwiązania",
          "6": "Koordynacja przygotowania potrzebnej infrastruktury (jeśli jest potrzebna) przeszkolenie zespołu",
          "7": "Bieżąca obsługa i wsparcie zespołu utrzymanie infrastruktury",
          "8": "Nasze doświadczenie przekazujemy wdrażając zespół budowy. Dostosowujemy narzędzia oraz etapy szkolenia i wsparcia dostosowując do porze naszych Klientów."
        },
        "investor": {
          "title": "Rozwiązania BIM dla Inwestorów",
          "1": "BIM dla Inwestorów-Jak możemy wykorzystać narzędzia BIM?",
          "2": "Inwestowanie to skomplikowany i wielowątkowy proces, w którym pozyskanie kluczowych danych na poszczególnych etapach inwestycji jest bardzo istotne. Dlatego też ważnym aspektem inwestycji jest wykorzystanie efektywnych narzędzi do planowania i projektowania inwestycji. Każdy inwestor powinien bazować na rzetelnych danych o inwestycji, które pozwolą mu na odpowiednie zaplanowanie projektu od finansowania inwestycji, aż po komercjalizację. Dane ekonomiczne i finansowe jakie można generować z modelu zmniejszają ryzyko inwestycyjne i ułatwiają podejmowanie decyzji. Archicad jest również do tego stworzony!",
          "3": "Analiza przestrzenna i planowanie na etapie pozyskania gruntów:",
          "4": "Narzędzia do modelowania BIM umożliwiają bardzo efektywne planowanie pierwszych działań i wytycznych do projektu. Analiza chłonności działki, jak równie analiza nasłonecznienia czy zacienienia są jednym z ważniejszych problemów z jakimi projektanci i inwestorzy się zmagają, które niejednokrotnie na późniejszym etapie projektu powodują szereg kosztownych i czasochłonnych zmian.",
          "5": "Monitorowanie kosztów:",
          "6": "Model BIM dla Inwestora to również baza informacji o kosztach inwestycji. Wartości przedmiarowe, analiza zastosowania rozwiązań zastępczych to przysłowiowy chleb powszedni. Bieżąca analiza inwestycji dzięki zastosowania modelowania BIM daje wiele korzyści dla zarządzających inwestycją od strony inwestora.",
          "7": "Narzędzia sprzedaży:",
          "8": "Komercjalizacja inwestycji jest ważnym elementem całego procesu. Dlatego też ten aspekt możemy ułatwić Inwestorom. Dzięki zastosowaniu sprawdzonych narzędzi mamy możliwość atrakcyjnej prezentacji projektu.",
        },
        "ask": "Masz więcej pytań?",
        "meet": "Spotkajmy się!",
        "signature": "DVM Group, Szervita Square"
      },
      "aboutUs": {
        "title": "O nas",
        "subtitle": "Dlaczego warto z nami współpracować?",
        "experince": {
          "title": "Doświadczenie",
          "content": "Nasz zespół to grupa czynnych projektantów, którzy wykorzystują nasze rozwiązania w codziennej pracy. Wiemy jak ułatwić Ci pracę w środowisku BIM."
        },
        "technology": {
          "title": "Technologia i praktyka",
          "content": "Nasz zespół to doświadczony zespół specjalistów, którzy na co dzień pracują również  w Naszej Grupie Firm: BIM Monumesnts oraz Digital Building Data pracując w najlepszymi firmami architektonicznymi, Inwestorami i Wykonawcami w Polsce"
        },
        "it": {
          "title": "Rozwiązania IT & BIM",
          "content": "Dostosowujemy rozwiązania do potrzeb Naszych Klientów.  Wspólnie z naszymi partenarmi IT możemy dastrczyć kompletne rozwiązanie."
        }
      },
      "trainings": {
        "title": "Szkolenia",
        "content.1": "Nauczymy Zespół najefektywniej  korzystać z naszych rozwiązań.",
        "content.2": "Przygotowujemy szkolenia dedykowane dla Pracowni Projektowych, Inwestorów czy też Generalnych Wykonawców",
        "subtitle": "ARCHICAD NIE MA PRZED NAMI TAJEMNIC!",
        "sectionTitle": "Dlaczego warto się z Nami szkolić?",
        "link": "POZNAJ SZCZEGÓŁY",
        "extra": "Potrzebujesz szkolenia dedykowanego dla Twojej Firmy?",
        "footer.button": "SKONTAKTUJ SIĘ Z NAMI",
      },
      "clients": {
        "title": "Nasi Klienci",
        "subtitle": "Historie naszych klientów",
        "content": "Znamy się na tym co robimy. Historie naszych klientów potwierdzają, że warto z nami współpracować"
      },
      "contact": {
        "title": "Kontakt",
        "subtitle": "Dane firmy i kontakt",
        "office": "BIURO SPRZEDAŻY",
        "companyData": "DANE FIRMY",
        "infoFooter": "Rozwiązania BIM dla Twojej Firmy",
        "form": {
          "name": "Imię i Nazwisko",
          "namePlaceholder": "Jan Kowalski",
          "phone": "Numer telefonu",
          "email": "E-mail",
          "emailPlaceholder": "jan.kowalski@gmail.com",
          "subject": "Temat wiadomośći",
          "message": "Wiadomość",
          "consent": "*Akceptuję warunki przechowywania oraz przetwarzania danych osobowych. Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysłaniem zapytania przez powyższy formularz kontaktowy.",
          "send": "Wyślij"
        }
      },
      "footer": {
        "copyright": "© 2023 BIMOTEKA | Wszelkie prawa zastrzeżone"
      },
      "404": {
        "notFound": "Strona nie została znaleziona",
        "content": "Przepraszamy! Nie mogliśmy znaleźć strony, której szukałeś.",
        "back": "Powrót do strony głównej"
      },
      "cookie": {
        "content": "Ta strona używa ciasteczek (cookies), dzięki którym nasz serwis może działać lepiej.",
        "link": "Dowiedz się więcej",
        "href": "https://wszystkoociasteczkach.pl/polityka-cookies/",
        "button": "OK"
      }
    },
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "pl", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;